import React from 'react';

import { PageHeader } from '../../layout/PageHeader';
import { PageHeading } from '../../layout/PageHeading';
import { SubBody } from '../../layout/SubBody';

import { UserEmailPreferences } from './UserEmailPreferences';

export const EmailPreferencesPage = () => {
  return (
    <>
      <PageHeader>
        <PageHeading>Email Preferences</PageHeading>
      </PageHeader>
      <SubBody>
        <UserEmailPreferences />
      </SubBody>
    </>
  );
};
