import React, { useState, useEffect, useContext, useCallback } from 'react';
import PropTypes from 'prop-types';

import createAuth0Client from '@auth0/auth0-spa-js';

let _initOptions;

const getAuth0Client = () => {
  return new Promise(async (resolve, reject) => {
    let client;
    if (!client && _initOptions) {
      try {
        client = await createAuth0Client(_initOptions);
        resolve(client);
      } catch (e) {
        reject(new Error('getAuth0Client Error', e));
      }
    }
  });
};

export const getTokenSilently = async (...p) => {
  const client = await getAuth0Client();
  const tokenSilently = await client.getTokenSilently(...p);
  return tokenSilently;
};

export const Auth0Provider = ({
  children,
  onRedirectCallback,
  ...initOptions
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);

  _initOptions = initOptions;

  const initAuth0 = useCallback(async () => {
    const client = await getAuth0Client();
    setAuth0(client);

    if (window.location.search.includes('code=')) {
      await client.handleRedirectCallback();
      onRedirectCallback();
    }

    const isAuthenticated = await client.isAuthenticated();
    setIsAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      const user = await client.getUser();
      setUser(user);
    }

    setLoading(false);
  }, [onRedirectCallback]);

  useEffect(() => {
    initAuth0();
  }, [initAuth0]);

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
    setLoading(false);
  };

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        loading,
        handleRedirectCallback,
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        logout: (...p) => auth0Client.logout(...p),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};

Auth0Provider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.instanceOf(Array),
  ]),
  onRedirectCallback: PropTypes.func,
};

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
