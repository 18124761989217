import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

export const Logo = ({ src, alt, height }) => (
  <LogoWrap height={height}>
    <img src={src} alt={alt} />
  </LogoWrap>
);

const LogoWrap = styled('div')({
  height: (props) => props.height,
  '& img': {
    maxWidth: '100%',
    height: '100%',
  },
});

Logo.defaultProps = {
  src: '',
  alt: 'logo',
  height: '60px',
};

Logo.propTypes = {
  src: PropTypes.string,
  alt: PropTypes.string,
  height: PropTypes.string,
};
