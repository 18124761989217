export const ROLES = {
  // form select options
  VIEWER: 'VIEWER',
  MANAGER: 'MANAGER',
  ADMIN: 'ADMIN',
  OWNER: 'OWNER',
  PROVIDER: 'PROVIDER',
  CONSUMER: 'CONSUMER',
  // control flow
  ADMIN_ADMIN: 'ADMIN_ADMIN',
  OWNER_MANAGER: 'OWNER_MANAGER',
  OWNER_VIEWER: 'OWNER_VIEWER',
  PROVIDER_MANAGER: 'PROVIDER_MANAGER',
  PROVIDER_VIEWER: 'PROVIDER_VIEWER',
  CONSUMER_MANAGER: 'CONSUMER_MANAGER',
  CONSUMER_VIEWER: 'CONSUMER_VIEWER',
  NO_ROLE: 'NO_ROLE',
};

export const URLS = {
  ROOT_URL: window?.env?.API_URL || '',
};

export const Const = {
  ...ROLES,
  ...URLS,
};
