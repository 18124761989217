import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

export const PageHeader = ({ children, ...props }) => (
  <PageHeaderStyled {...props}>{children}</PageHeaderStyled>
);

const PageHeaderStyled = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'baseline',
  opacity: 0,
  transformOrigin: 'center',
  animationDelay: '0ms',
  animation: 'fade-in ease 900ms forwards',
}));

PageHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.instanceOf(Array),
  ]),
};
