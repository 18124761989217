import React, { lazy } from 'react';

const OrganizationPageLazy = lazy(() =>
  import(/* webpackChunkName: "OrganizationPage" */ './ui/Organization').then(
    (module) => ({
      default: module.OrganizationPage,
    }),
  ),
);

export const OrganizationPage = () => <OrganizationPageLazy />;
