import { createSlice, createSelector } from '@reduxjs/toolkit';

import { baseApiWithToken, handleError } from '../../../store/api';

export const initialState = {
  loading: false,
  error: false,
  errorData: {
    status: '',
    message: '',
    errors: [],
  },
  consumers: [],
};

/** Slice */
export const {
  actions: { loading, loaded, remove, add, update, failure, clearError },
  reducer: consumers,
} = createSlice({
  name: 'consumers',
  initialState,
  reducers: {
    loading: (draft) => {
      draft.loading = true;
    },
    loaded: (draft, action) => {
      draft.loading = initialState.loading;
      const { consumers } = action.payload;
      draft.consumers = consumers;
    },
    add: (draft, action) => {
      draft.consumers.push(action.payload);
    },
    update: (draft, action) => {
      const { orgId } = action.payload;
      const index = draft.consumers.findIndex((item) => item.orgId === orgId);
      if (index !== -1) {
        draft.consumers[index] = action.payload;
      }
    },
    remove: (draft, action) => {
      const { orgId } = action.payload;
      const remaining = draft.consumers.filter((item) => item.orgId !== orgId);
      draft.consumers = remaining;
    },
    failure: (draft, action) => {
      draft.loading = initialState.loading;
      draft.error = true;
      draft.errorData = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = initialState.loading;
      draft.error = initialState.error;
      draft.errorData = initialState.errorData;
    },
  },
});

/** Selectors */
export const selectRootConsumers = (state) => state;
export const sliceSelector = (state) => state.consumers;

export const selectLoading = (state) => sliceSelector(state).loading;
export const selectError = (state) => sliceSelector(state).error;
export const selectErrorData = (state) => sliceSelector(state).errorData;
export const selectErrorResponse = createSelector(
  [selectError, selectErrorData],
  (error, data) => {
    return {
      hasError: error,
      hasErrorData: data,
    };
  },
);

export const selectConsumers = (state) => sliceSelector(state).consumers;

/** Side Effects */
export const createConsumer = (payload) => async (dispatch) => {
  return baseApiWithToken()
    .url('/consumer')
    .post(payload)
    .json((json) => dispatch(add(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const updateConsumer = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/consumer/${orgId}`)
    .put(payload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const deleteConsumer = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/consumer/${orgId}`)
    .delete()
    .json((json) => dispatch(remove(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getConsumers = () => async (dispatch) => {
  dispatch(loading());
  return baseApiWithToken()
    .url('/consumer')
    .get()
    .json((json) => dispatch(loaded(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};
