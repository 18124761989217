export const brandColors = [
  '#6e7579',
  '#a5afb5',
  '#94b8d9',
  '#39678f',
  '#d2e1ef',
  // loop

  '#6e7579',
  '#a5afb5',
  '#94b8d9',
  '#39678f',
  '#d2e1ef',
  // loop

  '#6e7579',
  '#a5afb5',
  '#94b8d9',
  '#39678f',
  '#d2e1ef',
];
