import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { Const } from '../store/constants';
import { selectRole } from '../store/reducers/rolesSlice';
import { PrivateRoute } from '../routes/PrivateRoute';

import { SuspenseLoader } from '../components/Loader/SuspenseLoader';
import { UsersPage } from '../pages/Users';
import { TelemetryProvidersPage } from '../pages/TelemetryProviders';
import { ConsumersPage } from '../pages/Consumers';
import { AboutPage } from '../pages/About';
import { ControlCenterPage } from '../pages/ControlCenter';
import { DestinationsPage } from '../pages/Destinations';
import { LoginPage } from '../pages/Login';
import { FourOhFour } from '../pages/FourOhFour';
import { DataOwnersPage } from '../pages/DataOwners';
import { MetricsPage } from '../pages/Metrics';
import { ProfilePage } from '../pages/Profile';
import { EmailPreferencesPage } from '../pages/EmailPreferences';
import { OrganizationPage } from '../pages/Organization';

export const Routes = () => {
  const { roleType } = useSelector(selectRole);

  return (
    <Suspense fallback={<SuspenseLoader message={''} bgColor="transparent" />}>
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>

        <PrivateRoute
          exact
          path="/"
          redirectPath="/metrics"
          redirect={
            roleType === Const.PROVIDER_MANAGER ||
            roleType === Const.CONSUMER_MANAGER ||
            roleType === Const.PROVIDER_VIEWER ||
            roleType === Const.CONSUMER_VIEWER
          }
          roles={[
            Const.ADMIN_ADMIN,
            Const.OWNER_MANAGER,
            Const.OWNER_VIEWER,
            Const.CONSUMER_VIEWER,
          ]}
        >
          <ControlCenterPage />
        </PrivateRoute>

        <PrivateRoute path="/control" roles={[Const.ADMIN_ADMIN]}>
          <ControlCenterPage />
        </PrivateRoute>

        <PrivateRoute
          path="/metrics"
          roles={[
            Const.ADMIN_ADMIN,
            Const.OWNER_MANAGER,
            Const.PROVIDER_MANAGER,
            Const.CONSUMER_MANAGER,
            Const.PROVIDER_VIEWER,
            Const.OWNER_VIEWER,
            Const.CONSUMER_VIEWER,
          ]}
        >
          <MetricsPage />
        </PrivateRoute>

        <PrivateRoute
          path="/owners"
          roles={[
            Const.ADMIN_ADMIN,
            Const.PROVIDER_MANAGER,
            Const.PROVIDER_VIEWER,
          ]}
        >
          <DataOwnersPage />
        </PrivateRoute>

        <PrivateRoute
          path="/destinations"
          roles={[Const.ADMIN_ADMIN, Const.CONSUMER_MANAGER]}
        >
          <DestinationsPage />
        </PrivateRoute>

        <PrivateRoute path="/providers" roles={[Const.ADMIN_ADMIN]}>
          <TelemetryProvidersPage />
        </PrivateRoute>

        <PrivateRoute path="/consumers" roles={[Const.ADMIN_ADMIN]}>
          <ConsumersPage />
        </PrivateRoute>

        <PrivateRoute
          path="/users"
          roles={[
            Const.ADMIN_ADMIN,
            Const.PROVIDER_MANAGER,
            Const.OWNER_MANAGER,
            Const.CONSUMER_MANAGER,
          ]}
        >
          <UsersPage />
        </PrivateRoute>

        <PrivateRoute
          path="/organization"
          roles={[
            Const.PROVIDER_MANAGER,
            Const.OWNER_MANAGER,
            Const.CONSUMER_MANAGER,
            Const.CONSUMER_VIEWER,
          ]}
        >
          <OrganizationPage />
        </PrivateRoute>

        <PrivateRoute
          path="/profile"
          roles={[
            Const.ADMIN_ADMIN,
            Const.PROVIDER_MANAGER,
            Const.OWNER_MANAGER,
            Const.CONSUMER_MANAGER,
            Const.OWNER_VIEWER,
            Const.CONSUMER_VIEWER,
            Const.PROVIDER_VIEWER,
          ]}
        >
          <ProfilePage />
        </PrivateRoute>

        <PrivateRoute path="/about" roles={[Const.ADMIN_ADMIN]}>
          <AboutPage />
        </PrivateRoute>

        <PrivateRoute
          path="/email_preferences"
          redirect={
            roleType === Const.ADMIN_ADMIN ||
            roleType === Const.OWNER_VIEWER ||
            roleType === Const.CONSUMER_VIEWER ||
            roleType === Const.PROVIDER_VIEWER
          }
          redirectPath="/"
          roles={[
            Const.PROVIDER_MANAGER,
            Const.OWNER_MANAGER,
            Const.CONSUMER_MANAGER,
          ]}
        >
          <EmailPreferencesPage />
        </PrivateRoute>

        <PrivateRoute
          roles={[
            Const.ADMIN_ADMIN,
            Const.PROVIDER_MANAGER,
            Const.OWNER_MANAGER,
            Const.CONSUMER_MANAGER,
          ]}
        >
          <FourOhFour />
        </PrivateRoute>
      </Switch>
    </Suspense>
  );
};
