import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

import { Anima } from '../../layout/Anima';
import { ReactComponent as Loading } from './loading.svg';

export const Loader = ({ show }) => (
  <Anima show={show}>
    <LoaderStyled>
      <Loading />
    </LoaderStyled>
  </Anima>
);

Loader.propTypes = {
  show: PropTypes.bool,
  enter: PropTypes.number,
  exit: PropTypes.number,
};

const LoaderStyled = styled('div')(({ theme }) => {
  return {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    zIndex: theme.zIndex.drawer + 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    width: '100vw',
  };
});
