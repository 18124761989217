import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { styled, Drawer } from '@material-ui/core';

import { useAuth0 } from '../../../auth/react-auth0-spa';
import { LinkSection } from './LinkSection';
import { IdentityDisplay } from './IdentityDisplay';
import { useSideBarLinksByRoleType } from '../useSideBarLinksByRoleType';

export const AppSideBar = memo(({ mobileOpen, onClose }) => {
  const { isAuthenticated } = useAuth0();
  const { dashboardLinks, manageLinks } = useSideBarLinksByRoleType();

  return (
    <DrawerStyled
      variant="permanent"
      open={mobileOpen}
      onClose={onClose}
      ModalProps={{ keepMounted: true }}
    >
      <ToobarSpacer />
      <IdentityDisplay />
      {isAuthenticated && (
        <>
          <LinkSection links={dashboardLinks} />
          <LinkSection links={manageLinks} />
        </>
      )}
    </DrawerStyled>
  );
});

AppSideBar.propTypes = {
  isAuthenticated: PropTypes.bool,
  mobileOpen: PropTypes.bool,
  onClose: PropTypes.func,
};

const ToobarSpacer = styled('div')(({ theme }) => ({
  ...theme.mixins.toolbar,
}));

const DrawerStyled = styled(Drawer)(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    width: 'var(--sidebar-drawer-width)',
    padding: '10px',
    background: 'var(--primary_base)',
    color: 'var(--primary_light)',
    transition: 'transform 250ms ease-in-out',
    [theme.breakpoints.up('md')]: {
      transform: 'translateX(0px})',
    },
    [theme.breakpoints.down('md')]: {
      transform: `translateX(${open ? '0px' : '-200px'})`,
    },
  },
}));
