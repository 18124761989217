import { createSlice, createSelector } from '@reduxjs/toolkit';
import { getData } from 'country-list';

import { baseApiWithToken, handleError } from '../../../store/api';
import { countryToFlag } from '../../../helpers/';

export const countryListWithFlag = getData().map((item) => ({
  ...item,
  icon: countryToFlag(item.code),
}));

export const initialState = {
  loading: false,
  error: false,
  errorData: {
    status: '',
    message: '',
    errors: [],
  },
  owners: [],
};

export const {
  actions: {
    loading,
    loaded,
    loadedById,
    update,
    add,
    remove,
    failure,
    clearError,
  },
  reducer: owners,
} = createSlice({
  name: 'owners',
  initialState,
  reducers: {
    loading: (draft) => {
      draft.loading = true;
    },
    loaded: (draft, action) => {
      draft.loading = initialState.loading;
      const { owners } = action.payload;
      draft.owners = owners;
    },
    loadedById: (draft, action) => {
      draft.loading = initialState.loading;
      draft.owners = action.payload;
    },
    add: (draft, action) => {
      draft.owners.push(action.payload);
    },
    update: (draft, action) => {
      const { orgId } = action.payload;
      const index = draft.owners.findIndex((item) => item.orgId === orgId);
      if (index !== -1) {
        draft.owners[index] = action.payload;
      }
    },
    remove: (draft, action) => {
      const { orgId } = action.payload;
      const remaining = draft.owners.filter((item) => item.orgId !== orgId);
      draft.owners = remaining;
    },
    failure: (draft, action) => {
      draft.loading = initialState.loading;
      draft.error = true;
      draft.errorData = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = initialState.loading;
      draft.error = initialState.error;
      draft.errorData = initialState.errorData;
    },
  },
});

/** Selectors */
export const selectRootOwners = (state) => state;
const sliceSelector = (state) => state.owners;

export const selectLoading = (state) => sliceSelector(state).loading;
export const selectError = (state) => sliceSelector(state).error;
export const selectErrorData = (state) => sliceSelector(state).errorData;
export const selectErrorResponse = createSelector(
  [selectError, selectErrorData],
  (error, data) => {
    return {
      hasError: error,
      hasErrorData: data,
    };
  },
);

export const selectOwners = (state) => sliceSelector(state).owners;

export const ownersCountrySelector = createSelector([selectOwners], (items) =>
  items?.filter((item) => item?.country),
);

export const ownerById = (state, id) =>
  sliceSelector(state).owners.find((items) => items.id === id);

/** Side Effects */
export const createOwner = (payload) => async (dispatch) => {
  return baseApiWithToken()
    .url('/owner')
    .post(payload)
    .json((json) => dispatch(add(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const updateOwner = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/owner/${orgId}`)
    .put(payload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const deleteOwner = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/owner/${orgId}`)
    .delete()
    .json((json) => dispatch(remove(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getOwners = () => async (dispatch) => {
  dispatch(loading());
  return baseApiWithToken()
    .url('/owner')
    .get()
    .json((json) => dispatch(loaded(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getOwnerById = () => async (dispatch, getStore) => {
  dispatch(loading());
  const { orgId } = await getStore().me?.me;
  return baseApiWithToken()
    .url(`/owner/${orgId}`)
    .get()
    .json((json) => dispatch(loadedById(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};
