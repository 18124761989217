import { useEffect, memo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { useAuth0 } from './auth/react-auth0-spa';
import { Const } from './store/constants';
import { getMe, getMePreference } from './store/reducers/meSlice';
import { selectRole } from './store/reducers/rolesSlice';

import { getUsers } from './pages/Users/store';
import { getOwners, getOwnerById } from './pages/DataOwners/store/';
import { getDestinations } from './pages/Destinations/store/';
import {
  getProviders,
  getProviderDevices,
} from './pages/TelemetryProviders/store/';
import { getConsumers } from './pages/Consumers/store/';
import { getMetrics, getMetricsAdmin } from './pages/Metrics/store/';
import { setOrganization } from './pages/Organization/store/';

/********************************
 * @AppServices
 ********************************/
export const AppServices = memo(() => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { logout } = useAuth0();
  const { roleType } = useSelector(selectRole);

  /* get current logged in user */
  useEffect(() => {
    dispatch(getMe());
  }, [dispatch, history, logout]);

  /* ADMIN */
  const admin = useCallback(
    () =>
      dispatch(getOwners()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(getDestinations()),
            dispatch(getProviders()),
            dispatch(getProviderDevices()),
            dispatch(getConsumers()),
            dispatch(getMetricsAdmin()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* DATA OWNER: MANAGER */
  const ownerManager = useCallback(
    () =>
      dispatch(getOwnerById()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(setOrganization()),
            dispatch(getMetrics()),
            dispatch(getProviders()),
            dispatch(getProviderDevices()),
            dispatch(getDestinations()),
            dispatch(getMePreference()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* DATA OWNER: MANAGER */
  const ownerViewer = useCallback(
    () =>
      dispatch(getOwnerById()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(setOrganization()),
            dispatch(getMetrics()),
            dispatch(getProviders()),
            dispatch(getProviderDevices()),
            dispatch(getDestinations()),
            dispatch(getMePreference()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* PROVIDER: MANAGER */
  const providerManager = useCallback(
    () =>
      dispatch(getProviders()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(getOwners()),
            dispatch(setOrganization()),
            dispatch(getMetrics()),
            dispatch(getMePreference()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* PROVIDER: VIEWER */
  const providerViewer = useCallback(
    () =>
      dispatch(getProviders()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(getOwners()),
            dispatch(setOrganization()),
            dispatch(getMetrics()),
            dispatch(getMePreference()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* CONSUMER: MANAGER */
  const consumerManager = useCallback(
    () =>
      dispatch(getConsumers()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(getOwners()),
            dispatch(setOrganization()),
            dispatch(getDestinations()),
            dispatch(getMetrics()),
            dispatch(getMePreference()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* CONSUMER: VIEWER */
  const consumerViewer = useCallback(
    () =>
      dispatch(getConsumers()).then(() =>
        dispatch(getUsers()).then(() =>
          Promise.all([
            dispatch(getOwners()),
            dispatch(setOrganization()),
            dispatch(getMetrics()),
            dispatch(getMePreference()),
          ]),
        ),
      ),
    [dispatch],
  );

  /* APIs by role type */
  useEffect(() => {
    if (roleType && roleType.length > 0) {
      const apiByRole = {
        [Const.CONSUMER_MANAGER]: () => consumerManager(),
        [Const.CONSUMER_VIEWER]: () => consumerViewer(),
        [Const.PROVIDER_MANAGER]: () => providerManager(),
        [Const.PROVIDER_VIEWER]: () => providerViewer(),
        [Const.OWNER_MANAGER]: () => ownerManager(),
        [Const.OWNER_VIEWER]: () => ownerViewer(),
        [Const.ADMIN_ADMIN]: () => admin(),
        [Const.NO_ROLE]: () => logout({ returnTo: window.location.origin }),
      };
      (apiByRole[roleType] || apiByRole[Const.NO_ROLE])();
    }
  }, [
    roleType,
    admin,

    ownerManager,
    providerManager,
    consumerManager,

    ownerViewer,
    providerViewer,
    consumerViewer,

    logout,
  ]);

  return null;
});
