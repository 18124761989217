import wretch from 'wretch';

import { Const } from './constants';

export const baseApi = (token) =>
  wretch()
    .url(Const.ROOT_URL)
    .auth(`Bearer ${token}`)
    .options({ headers: { Accept: 'application/json' } });

export const baseApiWithToken = () => {
  const token = getTokenFromStorage();
  return baseApi(token);
};

export const setTokenInStorage = (token) => {
  try {
    return sessionStorage.setItem('token', token);
  } catch (error) {
    console.error('failed to set token in locatStorage');
  }
};

export const getTokenFromStorage = () => {
  try {
    return sessionStorage.getItem('token');
  } catch (error) {
    console.error('failed to get token from locatStorage');
  }
};

export const handleError = (response) => {
  const statusCode = response?.status;
  let errorData;
  if (statusCode >= 400 && statusCode <= 499) {
    errorData = JSON.parse(response.text);
    errorData.statusCode = statusCode;
  } else {
    errorData = { status: statusCode, message: 'Fatal Error' };
  }
  return errorData;
};
