import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { useAuth0 } from '../auth/react-auth0-spa';
import { selectRole } from '../store/reducers/rolesSlice';

export const PrivateRoute = ({
  children,
  roles,
  redirect,
  redirectPath,
  ...rest
}) => {
  const { isAuthenticated } = useAuth0();
  const { roleType } = useSelector(selectRole);

  const canView = roles.includes(roleType);

  if (!isAuthenticated) {
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: rest.location },
        }}
      />
    );
  }

  if (redirect) {
    return <Redirect to={redirectPath} />;
  }

  return <Route {...rest}>{canView && children}</Route>;
};

PrivateRoute.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  roles: PropTypes.instanceOf(Object),
  redirect: PropTypes.bool,
  redirectPath: PropTypes.string,
};
