import React from 'react';

import {
  styled,
  FormControl,
  FormGroup,
  FormControlLabel,
} from '@material-ui/core';

import { MasterSwitch } from '../../components/Buttons/MasterSwitch';
import { SubBody } from '../../layout/SubBody';

import { useEmailPreferences } from './useEmailPreferences';

export const UserEmailPreferences = () => {
  const { prefs, isProvider, isConsumer, handleChange } = useEmailPreferences();

  return (
    <SubBody>
      <FormControl component="fieldset">
        <FormGroupCss>
          {!isProvider ? (
            <FormControlLabelCss
              label="When new data route is created"
              labelPlacement="start"
              control={
                <MasterSwitch
                  name="emailDestinationCreated"
                  toggled={Boolean(prefs?.emailDestinationCreated)}
                  onChange={handleChange}
                />
              }
            />
          ) : null}

          {!isProvider ? (
            <FormControlLabelCss
              label="When data route is disabled"
              labelPlacement="start"
              control={
                <MasterSwitch
                  name={'emailDestinationDisabled'}
                  toggled={Boolean(prefs?.emailDestinationDisabled)}
                  onChange={handleChange}
                />
              }
            />
          ) : null}

          {!isConsumer ? (
            <FormControlLabelCss
              label="When sent data is undeliverable"
              labelPlacement="start"
              control={
                <MasterSwitch
                  name="emailUnroutedData"
                  toggled={Boolean(prefs?.emailUnroutedData)}
                  onChange={handleChange}
                />
              }
            />
          ) : null}
        </FormGroupCss>
      </FormControl>
    </SubBody>
  );
};

const FormGroupCss = styled(FormGroup)({
  boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.25)',
  borderRadius: '0.3rem',
});

const FormControlLabelCss = styled(FormControlLabel)({
  minWidth: '590px',
  display: 'flex',
  justifyContent: 'space-between',
  padding: '15px',
  background: 'var(--primary_table_row_base)',
  margin: '0 !important',
  '& .MuiFormControlLabel-label': {
    fontSize: '15px !important',
  },
  '&:nth-child(even)': {
    background: 'var(--primary_table_row_alt)',
  },
  '&:first-of-type': {
    borderRadius: '0.3rem 0.3rem 0 0',
  },
  '&:last-of-type': {
    borderRadius: '0 0 0.3rem 0.3rem',
  },
});
