import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectRole } from '../store/reducers/rolesSlice';

export const useUserCanViewByRoleType = (roles) => {
  const { roleType } = useSelector(selectRole);
  const [canView, setCanView] = useState(false);

  useEffect(() => {
    if (roleType?.length > 0) {
      try {
        const includesRole = roles.includes(roleType);
        setCanView(includesRole);
      } catch (e) {
        console.error('Error', `Event: ${e}, roles: ${roles}`);
      }
    }
  }, [roleType, roles]);

  return [canView];
};
