import React, { memo } from 'react';
import { useSelector } from 'react-redux';

import { styled, Typography } from '@material-ui/core';

import { Anima } from '../../../layout/Anima';
import { meSelector } from '../../../store/reducers/meSlice';
import { selectRole } from '../../../store/reducers/rolesSlice';

export const IdentityDisplay = memo(() => {
  const { role: agentRole, orgName, agentName } = useSelector(meSelector);
  const { role } = useSelector(selectRole);

  return (
    <Anima show={true} type="zoom" config="250ms">
      <Branding>
        <Typography variant="h6">
          <span>{agentRole}</span>
        </Typography>
        {process.env.NODE_ENV === 'development' && <div>{role}</div>}
        <SubTitle variant="subtitle2">
          {agentName}
          <br />
          {orgName}
        </SubTitle>
      </Branding>
    </Anima>
  );
});

const Branding = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  marginBottom: '20px',
}));

const SubTitle = styled(Typography)(() => ({
  fontSize: '12px',
  color: 'var(--primary_offwhite)',
  textAlign: 'center',
}));
