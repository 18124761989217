import { combineReducers } from 'redux';

import { owners } from '../../pages/DataOwners/store/';
import { users } from '../../pages/Users/store/';
import { destinations } from '../../pages/Destinations/store/';
import { providers } from '../../pages/TelemetryProviders/store/';
import { consumers } from '../../pages/Consumers/store/';
import { metrics } from '../../pages/Metrics/store/';
import { organization } from '../../pages/Organization/store/';

import { me } from './meSlice';
import { roles } from './rolesSlice';

export const rootReducer = combineReducers({
  owners,
  destinations,
  providers,
  consumers,
  users,
  me,
  roles,
  organization,
  metrics,
});
