export const colorZebra = (index) =>
  index % 2 ? 'var(--primary_table_row_base)' : 'var(--primary_table_row_alt)';

export const isString = (item) => typeof item === 'string';

export const isValidNumber = (value) => (isNaN(value) ? 0 : value);

export const emptyObject = (obj) =>
  Object.entries(obj).length === 0 && obj.constructor === Object;

export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const whileLoading = ({ loading, count = 1, data = [] }) =>
  loading ? Array(count).fill(null) : data;

export const compareValues = (key, order = 'asc') => {
  return (a, b) => {
    if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
      return 0;
    }

    const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
    const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];

    let comparison = 0;
    if (varA > varB) {
      comparison = 1;
    } else if (varA < varB) {
      comparison = -1;
    }
    return order === 'desc' ? comparison * -1 : comparison;
  };
};

export const countryToFlag = (isoCode) => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;
};
