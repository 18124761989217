import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

export const Anima = ({ show, type, config, children }) => {
  const [render, setRender] = useState(show);

  useEffect(() => {
    if (show) {
      setRender(true);
    }
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };

  return (
    render && (
      <div
        style={{
          animation: `${show ? `${type}-in` : `${type}-out`} ${config ?? '1s'}`,
        }}
        onAnimationEnd={onAnimationEnd}
      >
        {children}
      </div>
    )
  );
};

Anima.propTypes = {
  config: PropTypes.string,
  type: PropTypes.string,
  children: PropTypes.node,
  show: PropTypes.bool,
};
