import { styled } from '@material-ui/core/styles';
import { Button, IconButton, Switch, withStyles } from '@material-ui/core';

const BaseButton = styled(Button)(() => ({
  textTransform: 'uppercase',
  boxShadow: '0 1px 2px 0px rgba(0, 0, 0, 0.3)',
  '&:disabled': {
    backgroundColor: 'var(--primary_table_heading)',
    boxShadow: 'none',
  },
}));

export const LoginButton = styled(BaseButton)(() => ({
  color: 'var(--primary_on_dark_text)',
  backgroundColor: 'var(--primary_accent)',
  '&:hover': {
    backgroundColor: 'var(--primary_accent_hover)',
  },
}));

export const AddButton = styled(BaseButton)(() => ({
  color: 'var(--primary_on_dark_text)',
  backgroundColor: 'var(--primary_accent)',
  '&:hover': {
    backgroundColor: 'var(--primary_accent_hover)',
  },
}));

export const DeleteButton = styled(BaseButton)(() => ({
  color: 'var(--primary_on_dark_text)',
  backgroundColor: '#d11a2a',
  '&:hover': {
    backgroundColor: '#e57373',
  },
}));

export const CancelButton = styled(BaseButton)(() => ({
  marginRight: '20px',
}));

export const EditIconButton = styled(IconButton)(() => ({
  '& svg': {
    fill: 'rgba(0, 0, 0, 0.45)',
  },
  '&:hover svg': {
    fill: '#338c06',
  },
  '&:disabled svg': {
    fill: 'rgba(0, 0, 0, 0.1)',
  },
}));

export const DeleteIconButton = styled(IconButton)(({ theme }) => ({
  '& svg': {
    fill: 'rgba(0, 0, 0, 0.45)',
  },
  '&:hover svg': {
    fill: '#d11a2a',
  },
  '&:disabled svg': {
    fill: 'rgba(0, 0, 0, 0.1)',
  },
}));

export const SwitchStyled = withStyles((theme) => {
  return {
    root: (props) => ({
      width: props?.mode === 'large' ? 36 : 28,
      height: props?.mode === 'large' ? 20 : 16,
      padding: 0,
      display: 'flex',
      overflow: 'visible',
    }),
    switchBase: {
      padding: 2,
      color: 'var(--primary_on_dark_gray_text)',
      '&$checked': {
        transform: 'translateX(12px)',
        color: 'var(--primary_on_dark_text)',
        '& + $track': {
          opacity: 1,
          backgroundColor: '#03b302',
          borderColor: 'var(--primary_on_dark_text)',
        },
      },
    },
    thumb: (props) => ({
      marginTop: props?.mode === 'large' ? -3 : 'auto',
      width: props?.mode === 'large' ? 22 : 12,
      height: props?.mode === 'large' ? 22 : 12,
    }),
    track: (props) => ({
      border: `1px solid var(--primary_on_dark_gray_text)`,
      borderRadius: props?.mode === 'large' ? 24 / 2 : 16 / 2,
      opacity: 1,
      backgroundColor: 'var(--primary_on_dark_text)',
    }),
    checked: {},
  };
})(Switch);
