import React from 'react';

import {
  Assignment as AssignmentIcon,
  BarChart as BarChartIcon,
  Room as RoomIcon,
  SettingsInputComponent as SettingsInputComponentIcon,
  Kitchen as KitchenIcon,
  Group as GroupIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  Inbox as InboxIcon,
} from '@material-ui/icons';

import { Const } from '../../store/constants';

export const dashboardLinkList = [
  {
    path: '/',
    exact: true,
    name: 'Control Center',
    icon: <AssignmentIcon fontSize="small" htmlColor="#fff" />,
    role: [Const.ADMIN_ADMIN, Const.OWNER_MANAGER, Const.OWNER_VIEWER],
  },
  {
    path: '/metrics',
    name: 'Metrics',
    icon: <BarChartIcon fontSize="small" htmlColor="#fff" />,
    role: [
      Const.ADMIN_ADMIN,
      Const.PROVIDER_MANAGER,
      Const.OWNER_MANAGER,
      Const.OWNER_VIEWER,
      Const.CONSUMER_MANAGER,
      Const.PROVIDER_VIEWER,
      Const.CONSUMER_VIEWER,
    ],
  },
];

export const manageLinkList = [
  {
    path: '/owners',
    name: 'Data Owners',
    icon: <RoomIcon fontSize="small" htmlColor="#fff" />,
    role: [Const.ADMIN_ADMIN, Const.PROVIDER_MANAGER, Const.PROVIDER_VIEWER],
  },
  {
    path: '/destinations',
    name: 'Destinations',
    icon: <SettingsInputComponentIcon fontSize="small" htmlColor="#fff" />,
    role: [Const.ADMIN_ADMIN, Const.CONSUMER_MANAGER],
  },
  {
    path: '/providers',
    name: 'Providers',
    icon: <KitchenIcon fontSize="small" htmlColor="#fff" />,
    role: [Const.ADMIN_ADMIN],
  },
  {
    path: '/consumers',
    name: 'Consumers',
    icon: <InboxIcon fontSize="small" htmlColor="#fff" />,
    role: [Const.ADMIN_ADMIN],
  },
  {
    path: '/users',
    name: 'Users',
    icon: <GroupIcon fontSize="small" htmlColor="#fff" />,
    role: [
      Const.ADMIN_ADMIN,
      Const.PROVIDER_MANAGER,
      Const.OWNER_MANAGER,
      Const.CONSUMER_MANAGER,
    ],
  },
  {
    path: '/organization',
    name: 'Organization',
    altName: {
      matches: [Const.CONSUMER_MANAGER, Const.CONSUMER_VIEWER],
      name: 'Integration',
    },
    icon: <SupervisedUserCircleIcon fontSize="small" htmlColor="#fff" />,
    role: [
      Const.PROVIDER_MANAGER,
      Const.OWNER_MANAGER,
      Const.CONSUMER_MANAGER,
      Const.CONSUMER_VIEWER,
    ],
  },
];
