import React, { lazy } from 'react';

const ConsumersPageLazy = lazy(() =>
  import(/* webpackChunkName: "ConsumersPage" */ './ui/Consumers').then(
    (module) => ({
      default: module.ConsumersPage,
    }),
  ),
);

export const ConsumersPage = () => <ConsumersPageLazy />;
