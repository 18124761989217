import React, { lazy } from 'react';

const MetricsPageLazy = lazy(() =>
  import(/* webpackChunkName: "MetricsPage" */ './ui/Metrics').then(
    (module) => ({
      default: module.MetricsPage,
    }),
  ),
);

export const MetricsPage = (props) => <MetricsPageLazy {...props} />;
