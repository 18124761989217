import {
  createSlice,
  createAsyncThunk,
  createSelector,
} from '@reduxjs/toolkit';

import { eachDayOfInterval, formatWithOptions } from 'date-fns/fp';

import { brandColors } from '../../../theme/brandColors';
import { baseApiWithToken } from '../../../store/api';

/** Helpers */
const dateToStringFormat = formatWithOptions({}, 'MMMM yyyy');

export const sum = (array) =>
  array.reduce(
    (acc, current) => current.map((num, idx) => (acc[idx] || 0) + num),
    [],
  );

export const setOffset = (date) =>
  date.getTime() + date.getTimezoneOffset() * 60000;

export const deDupe = (array, key) =>
  array.filter(
    (object, position, group) =>
      group.map((item) => item[key]).indexOf(object[key]) === position,
  );

export const mapToDates = (group, dates) =>
  group?.map((item, index) => ({
    x: dates[index],
    y: item,
  }));

export const createDateRange = (start, end) =>
  eachDayOfInterval({
    start: setOffset(new Date(start)),
    end: setOffset(new Date(end)),
  });

export const groupByKey = (array, key) =>
  array?.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue[key]]: (accumulator[currentValue[key]] || []).concat(
        currentValue,
      ),
    }),
    [],
  );

/** End Helpers */

export const getMetricLogs = createAsyncThunk(
  'metrics/getMetricLogsStatus',
  async (payload) => {
    const url = `/device/log?ownerUri=${payload.uri}&providerId=${payload.id}&limit=10`;
    return await baseApiWithToken()
      .url(url)
      .get()
      .json((json) => json.entries)
      .catch((error) => error?.status);
  },
);

export const getMetrics = createAsyncThunk(
  'metrics/getMetricsStatus',
  async () =>
    await baseApiWithToken()
      .url('/metric')
      .get()
      .json((json) => json)
      .catch((error) => error?.status),
);

export const getMetricsAdmin = createAsyncThunk(
  'metrics/getMetricsAdminStatus',
  async () =>
    await baseApiWithToken()
      .url('/metric/admin')
      .get()
      .json((json) => json)
      .catch((error) => error?.status),
);

export const getMetricsProviderSummary = createAsyncThunk(
  'metrics/getMetricsProviderSummaryStatus',
  async () =>
    await baseApiWithToken()
      .url(`/metric/provider/summary`)
      .get()
      .json((json) => json)
      .catch((error) => error?.status),
);

export const getMetricsAdminByProviderId = createAsyncThunk(
  'metrics/getMetricsAdminByProviderIdStatus',
  async (id) =>
    await baseApiWithToken()
      .url(`/metric/admin?providerId=${id}`)
      .get()
      .json((json) => json)
      .catch((error) => error?.status),
);

export const getMetricsConsumersSummary = createAsyncThunk(
  'metrics/getMetricsConsumersSummaryStatus',
  async () =>
    await baseApiWithToken()
      .url(`/consumer`)
      .get()
      .json((json) => json)
      .catch((error) => error?.status),
);

export const getMetricsAdminByConsumerId = createAsyncThunk(
  'metrics/getMetricsAdminByConsumerIdStatus',
  async (id) =>
    await baseApiWithToken()
      .url(`/metric/admin?consumerId=${id}`)
      .get()
      .json((json) => json)
      .catch((error) => error?.status),
);

export const getConsumerLag = createAsyncThunk(
  'metrics/getConsumerLagStatus',
  async (id, { getState }) => {
    const role = getState()?.roles?.role;
    const path = role === 'ADMIN' ? '/metric/admin/lag' : '/metric/lag';
    return await baseApiWithToken()
      .url(`${path}?consumerId=${id}`)
      .get()
      .json((json) => json)
      .catch((error) => error?.status);
  },
);

export const { reducer: metrics } = createSlice({
  name: 'metrics',
  initialState: {
    loading: false,
    errorMessage: '',
    logs: {
      loadingLogs: false,
      errorMessage: '',
      logs: [],
    },
    summary: [],
    consumers: [],
    lag: [],
    metricsByConsumerId: {},
    metricsByProviderId: {},
    metrics: {
      start: null,
      end: null,
      totalDevices: null,
      totalMessages: null,
      metrics: [],
    },
  },
  reducers: {},
  extraReducers: {
    [getMetricLogs.pending]: (draft) => {
      draft.logs.loadingLogs = true;
    },
    [getMetricLogs.fulfilled]: (draft, action) => {
      draft.logs.loadingLogs = false;
      draft.logs.logs = action.payload;
    },
    [getMetricLogs.rejected]: (draft, action) => {
      draft.logs.loadingLogs = false;
      draft.logs.errorMessage = action.payload;
    },
    [getConsumerLag.fulfilled]: (draft, action) => {
      draft.lag = action.payload;
    },
    [getConsumerLag.rejected]: (draft, action) => {
      draft.logs.errorMessage = action.payload;
    },
    [getMetrics.pending]: (draft) => {
      draft.loading = true;
    },
    [getMetrics.fulfilled]: (draft, action) => {
      draft.loading = false;
      draft.metrics = action.payload;
    },
    [getMetrics.rejected]: (draft, action) => {
      draft.loading = false;
      draft.errorMessage = action.payload;
    },
    [getMetricsAdminByConsumerId.pending]: (draft) => {
      draft.loading = true;
    },
    [getMetricsAdminByConsumerId.fulfilled]: (draft, action) => {
      draft.loading = false;
      draft.metricsByConsumerId = action.payload;
    },
    [getMetricsAdminByConsumerId.rejected]: (draft, action) => {
      draft.loading = false;
      draft.errorMessage = action.payload;
    },
    [getMetricsAdminByProviderId.pending]: (draft) => {
      draft.loading = true;
    },
    [getMetricsAdminByProviderId.fulfilled]: (draft, action) => {
      draft.loading = false;
      draft.metricsByProviderId = action.payload;
    },
    [getMetricsAdminByProviderId.rejected]: (draft, action) => {
      draft.loading = false;
      draft.errorMessage = action.payload;
    },
    [getMetricsProviderSummary.pending]: (draft) => {
      draft.loading = true;
    },
    [getMetricsProviderSummary.fulfilled]: (draft, action) => {
      draft.loading = false;
      draft.summary = action.payload;
    },
    [getMetricsProviderSummary.rejected]: (draft, action) => {
      draft.loading = false;
      draft.errorMessage = action.payload;
    },
    [getMetricsConsumersSummary.pending]: (draft) => {
      draft.loading = true;
    },
    [getMetricsConsumersSummary.fulfilled]: (draft, action) => {
      draft.loading = false;
      draft.consumers = action.payload.consumers;
    },
    [getMetricsConsumersSummary.rejected]: (draft, action) => {
      draft.loading = false;
      draft.errorMessage = action.payload;
    },
    [getMetricsAdmin.pending]: (draft) => {
      draft.loading = true;
    },
    [getMetricsAdmin.fulfilled]: (draft, action) => {
      draft.loading = false;
      draft.metrics = action.payload;
    },
    [getMetricsAdmin.rejected]: (draft, action) => {
      draft.loading = false;
      draft.errorMessage = action.payload;
    },
  },
});

/**  Selectors */
export const selectMetricsRoot = (state) => state.metrics;
export const selectMetricsLoading = (state) => state.metrics.loading;
export const selectMetricsErrorMessage = (state) => state.metrics.errorMessage;
export const selectMetricslogs = (state) => state.metrics.logs;
export const selectMetricsSummary = (state) => state.metrics.summary;
export const selectMetricsConsumers = (state) => state.metrics.consumers;
export const selectConsumerLag = (state) => state.metrics.lag;

export const startDateSelector = (state) =>
  dateToStringFormat(new Date(selectMetricsRoot(state)?.metrics?.start));

export const endDateSelector = (state) =>
  dateToStringFormat(new Date(selectMetricsRoot(state)?.metrics?.end));

export const selectMetricsByProviderId = (state) =>
  state.metrics.metricsByProviderId;

const emptyArray = [];

export const selectGroupedProvidersByOwnerName = createSelector(
  (state) => state.metrics.metricsByProviderId,
  (_, name) => name,
  (data, name) => {
    const { end, start, totalDevices, totalMessages, metrics } = data || {};
    const grouped = Object.entries(groupByKey(metrics || emptyArray, name));
    const sorted = grouped.sort((a, b) =>
      a[0] < b[0] ? 1 : b[0] < a[0] ? -1 : 0,
    );
    return {
      start,
      end,
      totalDevices,
      totalMessages,
      group: [...sorted],
    };
  },
);

export const selectMetricsByOwnerName = createSelector(
  [selectMetricsRoot],
  (metricsRoot) => {
    const { metrics: rootMetrics } = metricsRoot || {};
    const {
      end,
      start,
      totalDevices,
      totalMessages,
      metrics: subMetrics,
    } = rootMetrics || {};
    const addDates = subMetrics.map((item) => ({ ...item, start, end }));
    const grouped = Object.entries(
      groupByKey(addDates || emptyArray, 'ownerName'),
    );
    const sorted = grouped.sort((a, b) =>
      a[0] < b[0] ? 1 : b[0] < a[0] ? -1 : 0,
    );
    return {
      start,
      end,
      totalDevices,
      totalMessages,
      group: [...sorted],
    };
  },
);

export const barChartDeviceCountsSelector = createSelector(
  [selectMetricsRoot],
  (metricsRoot) => {
    const { start, end, metrics } = metricsRoot.metrics;

    const dates = eachDayOfInterval({
      start: setOffset(new Date(start)),
      end: setOffset(new Date(end)),
    });

    const groupedById = Object.entries(
      groupByKey(metrics || emptyArray, 'providerId'),
    ).map((item, index) => {
      const group = [];
      if (item[1]?.length > 1) {
        item[1].map((device) => group.push(device.deviceCounts));
      }
      return {
        deviceCounts: item[1].length > 1 ? sum(group) : item[1][0].deviceCounts,
        name: item[1][0].providerName,
        id: item[1][0].providerId,
        color: brandColors[index],
      };
    });

    return groupedById?.map((item) => {
      const group =
        item.deviceCounts.length === 0
          ? new Array(31).fill(0)
          : item.deviceCounts;
      return group.map((countPerDay, index) => ({
        x: dates[index],
        y: countPerDay,
        name: item.name,
        value: item.id,
        color: item.color,
      }));
    });
  },
);

export const barChartLegendSelector = createSelector(
  [selectMetricsRoot],
  (metricsRoot) => {
    const { metrics } = metricsRoot.metrics;
    return deDupe(metrics, 'providerId')?.map((item, index) => ({
      name: item.providerName,
      value: item.providerId,
      fill: brandColors[index],
      symbol: { type: 'square' },
    }));
  },
);

export const selectGroupedConsumersByOwnerName = createSelector(
  (state) => state.metrics.metricsByConsumerId,
  (_, name) => name,
  (data, name) => {
    const { end, start, totalDevices, totalMessages, metrics } = data || {};
    return {
      start,
      end,
      totalDevices,
      totalMessages,
      group: [...Object.entries(groupByKey(metrics || emptyArray, name))],
    };
  },
);

export const selectConsumerDeviceCount = createSelector(
  (state) => state.metrics.metrics,
  (_, uri) => uri,
  (metricsRoot, uri) => {
    const { start = new Date(), end = new Date(), metrics } = metricsRoot;

    const selectedMetrics = metrics?.filter(
      (item) => item.destinationName === uri,
    );

    const dates = eachDayOfInterval({
      start: setOffset(new Date(start)),
      end: setOffset(new Date(end)),
    });

    const chartNumbers = selectedMetrics?.map((item) => {
      return item?.deviceCounts?.map((countPerDay, index) => ({
        x: dates[index],
        y: countPerDay,
        name: item.providerName,
        value: item.providerId,
        color: brandColors[index],
      }));
    });

    const chartLegend = selectedMetrics?.map((item, index) => ({
      name: item.providerName,
      value: item.providerId,
      fill: brandColors[index],
      symbol: { type: 'square' },
    }));

    return { chartNumbers, chartLegend, selectedMetrics };
  },
);

export const selectConsumerAdminDeviceCount = createSelector(
  (state) => state.metrics.metricsByConsumerId,
  (_, uri) => uri,
  (metricsRoot, uri) => {
    const { start = new Date(), end = new Date(), metrics } = metricsRoot;

    const selectedMetrics = metrics?.filter(
      (item) => item.destinationName === uri,
    );

    const dates = eachDayOfInterval({
      start: setOffset(new Date(start)),
      end: setOffset(new Date(end)),
    });

    const chartNumbers = selectedMetrics?.map((item) =>
      item?.deviceCounts?.map((countPerDay, index) => ({
        x: dates[index],
        y: countPerDay,
        name: item.providerName,
        value: item.providerId,
        color: brandColors[index],
      })),
    );

    const chartLegend = selectedMetrics?.map((item, index) => ({
      name: item.providerName,
      value: item.providerId,
      fill: brandColors[index],
      symbol: { type: 'square' },
    }));

    return { chartNumbers, chartLegend, selectedMetrics };
  },
);
