import React, { lazy } from 'react';

const DestinationsPageLazy = lazy(() =>
  import(/* webpackChunkName: "DestinationsPage" */ './ui/Destinations').then(
    (module) => ({
      default: module.DestinationsPage,
    }),
  ),
);

export const DestinationsPage = () => <DestinationsPageLazy />;
