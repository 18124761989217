import React from 'react';
import PropTypes from 'prop-types';

import { styled, Typography } from '@material-ui/core';

export const PageHeading = ({
  children,
  className = '',
  variant = 'h4',
  ...rest
}) => (
  <PageHeadingStyled variant={variant} className={className} {...rest}>
    {children}
  </PageHeadingStyled>
);

PageHeading.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.instanceOf(Array),
  ]),
  className: PropTypes.string,
  variant: PropTypes.string,
};

const PageHeadingStyled = styled(Typography)(({ theme }) => ({
  fontWeight: '600',
  fontSize: '32px',
  color: 'var(--primary_base)',
  margin: theme.spacing(2.4, 1.1, 3, 1.1),
}));
