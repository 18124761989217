import React, { lazy } from 'react';

const TelemetryProvidersPageLazy = lazy(() =>
  import(
    /* webpackChunkName: "TelemetryProvidersPage" */ './ui/TelemetryProviders'
  ).then((module) => ({
    default: module.TelemetryProvidersPage,
  })),
);

export const TelemetryProvidersPage = () => <TelemetryProvidersPageLazy />;
