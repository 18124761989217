import { createSlice, createSelector } from '@reduxjs/toolkit';

import { baseApiWithToken, handleError } from '../../../store/api';
import { Const } from '../../../store/constants';

export const initialState = {
  loading: false,
  error: false,
  errorData: {},
  organization: {},
};

/** Slice */
export const {
  actions: { setOrg, update, generate, failure, clearError, createSource },
  reducer: organization,
} = createSlice({
  name: 'organization',
  initialState,
  reducers: {
    setOrg: (draft, action) => {
      draft.organization = action.payload;
    },
    update: (draft, action) => {
      draft.organization = action.payload;
    },
    generate: (draft, action) => {
      draft.organization = { ...draft.organization, ...action.payload };
    },
    failure: (draft, action) => {
      draft.loading = false;
      draft.error = true;
      draft.errorData = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = false;
      draft.error = false;
      draft.errorData = {};
    },
  },
});

export const selectOrgRoot = (state) => state.organization;
const sliceSelector = (state) => state.organization;

export const selectOrg = (state) => sliceSelector(state).organization;

export const selectLoading = (state) => sliceSelector(state).loading;
export const selectError = (state) => sliceSelector(state).error;
export const selectErrorData = (state) => sliceSelector(state).errorData;
export const selectErrorResponse = createSelector(
  [selectError, selectErrorData],
  (error, data) => {
    return {
      hasError: error,
      hasErrorData: data,
    };
  },
);

const orgTypePath = (type) => {
  let path = '';
  if (type === Const.PROVIDER) {
    path = '/provider';
  } else if (type === Const.OWNER) {
    path = '/owner';
  } else if (type === Const.CONSUMER) {
    path = '/consumer';
  }
  return path;
};

export const generateKey = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/consumer/${orgId}/key`)
    .post()
    .json((json) => dispatch(generate(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const addSource = (payload) => async (dispatch) => {
  const { orgId, sources, source } = payload;
  const finalPayload = {
    ...payload,
    sources: [...sources, source],
  };
  return baseApiWithToken()
    .url(`/provider/${orgId}`)
    .put(finalPayload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const deleteSource = (payload) => async (dispatch, getStore) => {
  const { org, source } = payload.data;
  const { orgId, sources } = org;
  const finalPayload = {
    ...org,
    sources: sources.filter((item) => item !== source),
  };
  return baseApiWithToken()
    .url(`/provider/${orgId}`)
    .put(finalPayload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const setOrganization = () => async (dispatch, getStore) => {
  const { orgId, orgType } = (await getStore()?.me?.me) || {};
  const url = `${orgTypePath(orgType)}/${orgId}`;
  return baseApiWithToken()
    .url(url)
    .get()
    .json((json) => dispatch(setOrg(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const updateOrganization = (payload) => async (dispatch, getStore) => {
  const { orgId, orgType } = (await getStore()?.me?.me) || {};
  const url = `${orgTypePath(orgType)}/${orgId}`;
  return baseApiWithToken()
    .url(url)
    .put(payload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};
