import React, { lazy } from 'react';

const DataOwnersPageLazy = lazy(() =>
  import(/* webpackChunkName: "DataOwnersPage" */ './ui/DataOwners').then(
    (module) => ({
      default: module.DataOwnersPage,
    }),
  ),
);

export const DataOwnersPage = () => <DataOwnersPageLazy />;
