import { createSlice, createSelector } from '@reduxjs/toolkit';

import { baseApiWithToken, handleError } from '../../../store/api';

export const initialState = {
  loading: false,
  error: false,
  errorData: {
    status: '',
    message: '',
    errors: [],
  },
  destinations: [],
};

/** Slice */
export const {
  actions: { loading, loaded, remove, add, update, failure, clearError },
  reducer: destinations,
} = createSlice({
  name: 'destinations',
  initialState,
  reducers: {
    loading: (draft) => {
      draft.loading = true;
    },
    loaded: (draft, action) => {
      draft.loading = initialState.loading;
      const { destinations } = action.payload;
      draft.destinations = destinations;
    },
    add: (draft, action) => {
      draft.destinations.push(action.payload);
    },
    update: (draft, action) => {
      draft.loading = initialState.loading;
      const { destId } = action.payload;
      const index = draft.destinations.findIndex(
        (item) => item.destId === destId,
      );
      draft.destinations[index] = action.payload;
    },
    remove: (draft, action) => {
      const { destId } = action.payload;
      const remaining = draft.destinations.filter(
        (item) => item.destId !== destId,
      );
      draft.destinations = remaining;
    },
    failure: (draft, action) => {
      draft.loading = initialState.loading;
      draft.error = true;
      draft.errorData = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = initialState.loading;
      draft.error = initialState.error;
      draft.errorData = initialState.errorData;
    },
  },
});

/** Selectors */
export const selectRootDestinations = (state) => state;
const sliceSelector = (state) => state.destinations;

export const destinationDataSelector = (state) =>
  sliceSelector(state).destinations;

export const selectLoading = (state) => sliceSelector(state).loading;
export const selectError = (state) => sliceSelector(state).error;
export const selectErrorData = (state) => sliceSelector(state).errorData;
export const selectErrorResponse = createSelector(
  [selectError, selectErrorData],
  (error, data) => {
    return {
      hasError: error,
      hasErrorData: data,
    };
  },
);

const emptyArray = [];

export const groupByKey = (array, key) =>
  array?.reduce(
    (accumulator, currentValue) => ({
      ...accumulator,
      [currentValue[key]]: (accumulator[currentValue[key]] || []).concat(
        currentValue,
      ),
    }),
    [],
  );

export const selectDestinationsGroupedByOwnerName = createSelector(
  (state) => state.destinations,
  (destinations) => {
    const { destinations: destinationsList } = destinations || {};
    const grouped = Object.entries(
      groupByKey(destinationsList || emptyArray, 'ownerName'),
    );
    const sorted = grouped.sort((a, b) =>
      a[0] < b[0] ? 1 : b[0] < a[0] ? -1 : 0,
    );
    return {
      grouped: [...sorted],
    };
  },
);

/** Side Effects */
export const createDestination = (payload) => async (dispatch) => {
  return await baseApiWithToken()
    .url('/destination')
    .post(payload)
    .json((json) => dispatch(add(json)))
    .catch((error) => {
      console.log('ERROR: ', error);
      return dispatch(failure(handleError(error)));
    });
};

export const deleteDestination = (payload) => async (dispatch) => {
  const { destId } = payload;
  return await baseApiWithToken()
    .url(`/destination/${destId}`)
    .delete()
    .json((json) => dispatch(remove(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const editDestination = (payload) => async (dispatch) => {
  const { destId } = payload;
  return await baseApiWithToken()
    .url(`/destination/${destId}`)
    .put(payload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getDestinations = () => async (dispatch) => {
  dispatch(loading());
  return await baseApiWithToken()
    .url('/destination')
    .get()
    .json((json) => dispatch(loaded(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getDestinationsById = () => async (dispatch, getStore) => {
  dispatch(loading());
  const accountID = await getStore().routes.routes[0].destinationId;
  return await baseApiWithToken()
    .url(`/destination/${accountID}`)
    .get()
    .json((json) => dispatch(loaded(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};
