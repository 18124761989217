import React from 'react';
import ReactDOM from 'react-dom';

import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Auth0Provider } from './app/auth/react-auth0-spa';
import { authConfig } from './app/auth/auth_config';
import { App } from './app/App';
import { rootReducer } from './app/store/reducers';

import * as serviceWorker from './serviceWorker';

const store = configureStore({
  reducer: rootReducer,
  middleware: getDefaultMiddleware({
    thunk: true,
    immutableCheck: true,
    serializableCheck: {
      ignoredActions: ['me/failure'],
    },
  }),
});

const onRedirectCallback = () =>
  window.history.replaceState({}, document.title, window.location.pathname);

ReactDOM.render(
  <BrowserRouter>
    <Auth0Provider
      onRedirectCallback={onRedirectCallback}
      domain={authConfig.domain}
      client_id={authConfig.clientId}
      audience={authConfig.audience}
      redirect_uri={window.location.origin}
      useRefreshTokens={authConfig.useRefreshTokens}
      cacheLocation={authConfig.cacheLocation}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </BrowserRouter>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
// serviceWorker.register();
serviceWorker.unregister();

if (process.env.NODE_ENV === 'development') {
  // just add `mock: true` via dev tools to localStorage to turn on mocking
  const mock = sessionStorage.getItem('mock');
  if (mock) {
    const { worker } = require('./mocks/browser');
    worker.start();
  }
}
