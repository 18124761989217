import { createSlice, createSelector } from '@reduxjs/toolkit';

import { baseApiWithToken, handleError } from '../../../store/api';

export const initialState = {
  loading: false,
  error: false,
  errorData: {
    status: '',
    message: '',
    errors: [],
  },
  providers: [],
  devices: [],
};

/** Slice */
export const {
  actions: {
    loading,
    loaded,
    remove,
    add,
    update,
    addDevices,
    failure,
    clearError,
  },
  reducer: providers,
} = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    loading: (draft) => {
      draft.loading = true;
    },
    loaded: (draft, action) => {
      draft.loading = initialState.loading;
      const { providers } = action.payload;
      draft.providers = providers;
    },
    add: (draft, action) => {
      const parseUpdateTime = new Date(action.payload.updateTime);
      const newPayload = {
        ...action.payload,
        updateTime: parseUpdateTime.toISOString(),
      };
      draft.providers.push(newPayload);
    },
    update: (draft, action) => {
      const { orgId } = action.payload;
      const index = draft.providers.findIndex((item) => item.orgId === orgId);
      if (index !== -1) {
        draft.providers[index] = action.payload;
      }
    },
    remove: (draft, action) => {
      const { orgId } = action.payload;
      const remaining = draft.providers.filter((item) => item.orgId !== orgId);
      draft.providers = remaining;
    },
    addDevices: (draft, action) => {
      draft.devices = action.payload.devices;
    },
    failure: (draft, action) => {
      draft.loading = initialState.loading;
      draft.error = true;
      draft.errorData = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = initialState.loading;
      draft.error = initialState.error;
      draft.errorData = initialState.errorData;
    },
  },
});

/** Selectors */
export const selectRootProvidersForTest = (state) => state;
const sliceSelector = (state) => state.providers;

export const selectProviders = (state) => sliceSelector(state);

export const selectLoading = (state) => sliceSelector(state).loading;
export const selectError = (state) => sliceSelector(state).error;
export const selectErrorData = (state) => sliceSelector(state).errorData;
export const selectErrorResponse = createSelector(
  [selectError, selectErrorData],
  (error, data) => {
    return {
      hasError: error,
      hasErrorData: data,
    };
  },
);

export const selectProvidersSorted = createSelector(
  (state) => state.providers.providers,
  (providers) =>
    [...providers].sort((a, b) => (a.orgName > b.orgName ? 1 : -1)),
);

export const selectProviderDevices = (state) => sliceSelector(state).devices;

/** Side Effects */
export const getProviderDevices = (payload) => async (dispatch) => {
  return baseApiWithToken()
    .url('/device')
    .get()
    .json((json) => dispatch(addDevices(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const createProvider = (payload) => async (dispatch) => {
  return baseApiWithToken()
    .url('/provider')
    .post(payload)
    .json((json) => dispatch(add(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const updateProvider = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/provider/${orgId}`)
    .put(payload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const deleteProvider = (payload) => async (dispatch) => {
  const { orgId } = payload;
  return baseApiWithToken()
    .url(`/provider/${orgId}`)
    .delete()
    .json((json) => dispatch(remove(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getProviders = () => async (dispatch) => {
  dispatch(loading());
  return baseApiWithToken()
    .url('/provider')
    .get()
    .json((json) => dispatch(loaded(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};
