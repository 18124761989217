import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { selectRole } from '../../store/reducers/rolesSlice';
import { dashboardLinkList, manageLinkList } from './links';

export const useSideBarLinksByRoleType = () => {
  const { roleType } = useSelector(selectRole);
  const [dashboardLinks, setDashboardLinks] = useState([]);
  const [manageLinks, setManageLinks] = useState([]);

  useEffect(() => {
    const checkCanView = (list, type) =>
      list.filter((item) => item.role.includes(type));

    setDashboardLinks(checkCanView(dashboardLinkList, roleType));
    setManageLinks(checkCanView(manageLinkList, roleType));
  }, [roleType]);

  return { dashboardLinks, manageLinks, roleType };
};
