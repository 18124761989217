import React, { lazy } from 'react';

const ControlCenterLazy = lazy(() =>
  import(/* webpackChunkName: "ControlCenterPage" */ './ui/ControlCenter').then(
    (module) => ({
      default: module.ControlCenter,
    }),
  ),
);

export const ControlCenterPage = () => <ControlCenterLazy />;
