import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import { styled, AppBar, Toolbar, IconButton } from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';

import { useAuth0 } from '../../../auth/react-auth0-spa';
import { Logo } from '../../../components/Logo';
import { ProfileMenu } from '../../ProfileMenu';

export const AppHeader = memo(({ children, openDrawer }) => {
  const { isAuthenticated } = useAuth0();

  return (
    <AppBarStyled position="fixed" elevation={1} square>
      <Toolbar>
        <LogoWrap>
          <MenuButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={openDrawer}
          >
            <MenuIcon />
          </MenuButton>
          <Link to="/">
            <Logo
              height="32px"
              src="/images/CCDX_logo.svg"
              alt="Cold Chain Device Interchange"
            />
          </Link>
        </LogoWrap>
        {children}
        {isAuthenticated && <ProfileMenu />}
      </Toolbar>
    </AppBarStyled>
  );
});

AppHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.instanceOf(Array),
  ]),
  openDrawer: PropTypes.func,
  isAuthenticated: PropTypes.bool,
};

const LogoWrap = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
}));

const MenuButton = styled(IconButton)(({ theme }) => ({
  marginRight: theme.spacing(2),
  '& svg': {
    fill: 'var(--primary_base)',
  },
  [theme.breakpoints.up('lg')]: {
    display: 'none',
  },
}));

const AppBarStyled = styled(AppBar)(({ theme }) => ({
  height: 'var(--app-header-height)',
  background: '#fff',
  zIndex: theme.zIndex.drawer + 1,
  '& .MuiAvatar-root': {
    height: '30px',
    width: '30px',
    marginRight: '0px',
  },
  '& .MuiToolbar-root': {
    justifyContent: 'space-between',
    minHeight: '48px !important',
    paddingRight: '10px',
    paddingLeft: '10px',
  },
}));
