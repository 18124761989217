import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { NavLink } from 'react-router-dom';

import {
  makeStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';

import { useSideBarLinksByRoleType } from '../useSideBarLinksByRoleType';

const pickName = (item, role) => {
  if (!item?.altName) {
    return item?.name;
  }
  if (item?.altName.matches.includes(role)) {
    return item.altName.name;
  } else {
    return item.name;
  }
};

export const AppSideBarLink = memo(({ item, ...props }) => {
  const classes = useStyles(props);
  const { roleType } = useSideBarLinksByRoleType();
  const name = pickName(item, roleType);

  return (
    <ListItem className={classes.listItem}>
      <NavLink
        exact={item?.exact}
        strict
        to={item?.path}
        className={classes.navBarLink}
        activeClassName={classes.selected}
      >
        <div className={classes.innerLink}>
          <ListItemIcon className={classes.iconWrap}>{item?.icon}</ListItemIcon>
          <ListItemText className={classes.listItemText}>{name}</ListItemText>
        </div>
      </NavLink>
    </ListItem>
  );
});

AppSideBarLink.propTypes = {
  item: PropTypes.instanceOf(Object),
};

const useStyles = makeStyles(() => {
  return {
    listItem: {
      padding: 6,
      paddingLeft: '0',
      paddingRight: '0',
    },
    listItemText: {
      '& .MuiTypography-root': {
        fontSize: '12px',
        fontWeight: '400',
        letterSpacing: '0.1em',
      },
    },
    iconWrap: {
      minWidth: ' 25px',
      marginLeft: '14px',
    },
    innerLink: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      padding: '1px 0',
      '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
        fill: 'var(--primary_light)',
        fontSize: '15px',
      },
    },
    navBarLink: {
      display: 'block',
      width: '100%',
      borderRadius: 'var(--primary_base_border_radius)',
      '&:active': {
        transform: 'scale(0.98)',
      },
      '& > :not(.selected):hover': {
        background: 'var(--primary_accent_hover)',
        borderRadius: 'var(--primary_base_border_radius)',
      },
    },
    selected: {
      fontWeight: '700',
      color: 'var(--primary_light)',
      width: '100%',
      background: 'var(--primary_accent)',
      borderRadius: 'var(--primary_base_border_radius)',
      boxShadow: 'rgba(0, 0, 0, 0.3) 0px 1px 3px 0px',
      '& .MuiSvgIcon-root.MuiSvgIcon-fontSizeSmall': {
        fill: 'var(--primary_highlight)',
      },
    },
  };
});
