import { createSlice } from '@reduxjs/toolkit';

import { Const } from '../constants';

import { baseApi, setTokenInStorage } from '../api';
import { getTokenSilently } from '../../auth/react-auth0-spa';

export const initialState = {
  loading: false,
  error: false,
  errorMessage: '',
  me: {
    agentName: '',
    email: '',
    orgType: '',
    role: '',
    subject: '',
  },
  preference: {},
};

/** Slice */
export const {
  actions: { loading, loaded, loadedPrefs, failure, clearError },
  reducer: me,
} = createSlice({
  name: 'me',
  initialState,
  reducers: {
    loading: (draft) => {
      draft.loading = true;
    },
    loaded: (draft, action) => {
      draft.loading = false;
      draft.me = action.payload;
    },
    loadedPrefs: (draft, action) => {
      draft.loading = false;
      draft.preference = action.payload;
    },
    failure: (draft, action) => {
      draft.loading = false;
      draft.error = true;
      draft.errorMessage = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = false;
      draft.errorMessage = '';
      draft.error = false;
    },
  },
});

/** Selectors */
export const selectRootMe = (state) => state;
const sliceSelector = (state) => state.me;
export const isLoading = (state) => sliceSelector(state).loading;
export const hasError = (state) => sliceSelector(state).error;
export const errorMessage = (state) => sliceSelector(state).errorMessage;

export const mePrefsSelector = (state) => sliceSelector(state).preference;
export const meSelector = (state) => sliceSelector(state).me;
export const meRoleSelector = (state) => meSelector(state).role;
export const selectMeAdmin = (state) => meRoleSelector(state) === Const.ADMIN;

/** Side Effects */
export const getMe = () => async (dispatch) => {
  dispatch(loading());
  const token = await getTokenSilently();
  setTokenInStorage(token);
  return baseApi(token)
    .url('/agent/me')
    .get()
    .json((json) => {
      return dispatch(loaded(json));
    })
    .catch((error) => dispatch(failure(error?.status)));
};

export const getMePreference = () => async (dispatch) => {
  dispatch(loading());
  const token = await getTokenSilently();
  setTokenInStorage(token);
  return baseApi(token)
    .url('/agent/me/preference')
    .get()
    .json((json) => {
      return dispatch(loadedPrefs(json));
    })
    .catch((error) => dispatch(failure(error?.status)));
};

export const putMePreference = (data) => async (dispatch) => {
  dispatch(loading());
  const token = await getTokenSilently();
  setTokenInStorage(token);
  return baseApi(token)
    .url('/agent/me/preference')
    .put(data)
    .json((json) => {
      return dispatch(loadedPrefs(json));
    })
    .catch((error) => dispatch(failure(error?.status)));
};
