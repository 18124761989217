import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';
import { SwitchStyled } from './ButtonStyles';

export const MasterSwitch = ({
  name = 'switch',
  label = '',
  toggled = false,
  onChange,
  style = {},
}) => {
  return (
    <MasterSwitchWrap style={style}>
      <label
        style={{ marginRight: '10px' }}
        htmlFor={name}
        title={`${toggled ? 'Disable' : 'Enable'}`}
      >
        {label}
      </label>
      <SwitchStyled
        id={name}
        name={name}
        checked={toggled}
        onChange={onChange}
        value={toggled}
      />
    </MasterSwitchWrap>
  );
};

const MasterSwitchWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
  color: 'rgba(0, 0, 0, 0.54)',
  fontSize: '16px',
  fontWeight: '700',
});

MasterSwitch.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  toggled: PropTypes.bool,
  onChange: PropTypes.func,
  style: PropTypes.instanceOf(Object),
};
