import React, { lazy } from 'react';

const FourOhFourLazy = lazy(() =>
  import(/* webpackChunkName: "FourOhFour" */ './FourOhFour').then(
    (module) => ({
      default: module.FourOhFour,
    }),
  ),
);

export const FourOhFour = () => <FourOhFourLazy />;
