import React, { useRef, useState } from 'react';

import { ThemeProvider, styled, CssBaseline } from '@material-ui/core';

import { useAuth0 } from './auth/react-auth0-spa';
import { ScrollToTop } from './hooks/useScrollToTop';
import { AppServices } from './AppServices';
import { Loader } from './components/Loader/Loader';
import { AppSideBar } from './features/AppSideBar';
import { AppHeader } from './features/AppHeader';
import { Routes } from './routes';
import { theme } from './theme';

/********************************
 * @App
 ********************************/
export const App = () => {
  const { loading, isAuthenticated } = useAuth0();
  const mainBodyRef = useRef(null);

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => setMobileOpen(!mobileOpen);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <ScrollToTop target={mainBodyRef} />
      <AppMainLayout ref={mainBodyRef}>
        {!loading && (
          <>
            {isAuthenticated && (
              <>
                <AppServices />
                <AppHeader openDrawer={handleDrawerToggle} />
                <AppSideBar
                  mobileOpen={mobileOpen}
                  onClose={handleDrawerToggle}
                />
              </>
            )}
          </>
        )}
        <Main>
          {loading ? <Loader show={loading} /> : null}
          {loading && !isAuthenticated ? null : <Routes />}
        </Main>
      </AppMainLayout>
    </ThemeProvider>
  );
};

const AppMainLayout = styled('section')(({ theme }) => ({
  position: 'fixed',
  height: '100vh',
  width: '100vw',
  display: 'grid',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: `var(--sidebar-drawer-width) 1fr`,
    gridTemplateRows: `var(--app-header-height) 1fr`,
  },
  [theme.breakpoints.down('md')]: {
    gridTemplateColumns: `0px 1fr`,
    gridTemplateRows: `var(--app-header-height) 1fr`,
  },
  background: 'var(--primary_light)',
  overflow: 'hidden',
  overflowY: 'auto',
}));

const Main = styled('main')(() => ({
  marginTop: 'var(--app-header-height)',
  height: '100vh',
  padding: '15px 25px',
}));
