import React from 'react';
import PropTypes from 'prop-types';

import { styled } from '@material-ui/core';

export const SuspenseLoader = ({
  message = 'Loading...',
  bgColor = 'rgba(255,255,255, 0.8)',
}) => <SuspenseLoaderStyled bgcolor={bgColor}>{message}</SuspenseLoaderStyled>;

SuspenseLoader.propTypes = {
  message: PropTypes.string,
  bgColor: PropTypes.string,
};

const SuspenseLoaderStyled = styled('div')(({ bgcolor }) => ({
  position: 'absolute',
  zIndex: '3000',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  background: bgcolor,
  display: 'grid',
  placeContent: 'center',
  textAlign: 'center',
}));
