import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { List } from '@material-ui/core';

import { SubBody } from '../../../layout/SubBody';
import { AppSideBarLink } from './AppSideBarLink';

export const LinkSection = memo(({ links = [] }) => (
  <SubBody>
    <List style={{ paddingTop: 0, paddingBottom: 0 }}>
      {links.map((item, index) => (
        <AppSideBarLink key={index} item={item} />
      ))}
    </List>
  </SubBody>
));

LinkSection.propTypes = {
  links: PropTypes.instanceOf(Array).isRequired,
  title: PropTypes.string,
};
