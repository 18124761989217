import { createSlice } from '@reduxjs/toolkit';

export const initialState = {
  roleType: '',
  orgType: '',
  role: '',
};

/** Slice */
export const { reducer: roles } = createSlice({
  name: 'roles',
  initialState,
  reducers: {},
  extraReducers: {
    'me/loaded': (draft, action) => {
      const { payload } = action;
      const { role, orgType } = payload;
      draft.roleType = `${orgType}_${role}`;
      draft.orgType = role;
      draft.role = orgType;
    },
  },
});

export const selectRole = (state) => state.roles;
