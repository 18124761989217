import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { debounce } from 'lodash';

import { Const } from '../../store/constants';
import { mePrefsSelector, putMePreference } from '../../store/reducers/meSlice';
import { selectRole } from '../../store/reducers/rolesSlice';

export const useEmailPreferences = () => {
  const dispatch = useDispatch();
  const preferencesSelector = useSelector(mePrefsSelector);
  const { roleType } = useSelector(selectRole);

  const [prefs, setPrefs] = React.useState(preferencesSelector ?? {});
  React.useEffect(() => {
    setPrefs(preferencesSelector);
  }, [preferencesSelector]);

  const debouncedSave = React.useRef(
    debounce((nextValue) => dispatch(putMePreference(nextValue)), 350),
  ).current;

  const handleChange = (e) => {
    const { name, checked } = e?.target ?? {};
    const update = {
      ...prefs,
      [name]: Boolean(checked),
    };
    debouncedSave(update);
    setPrefs(update);
  };

  const isProvider =
    roleType === Const.PROVIDER_MANAGER || roleType === Const.PROVIDER_VIEWER;
  const isConsumer =
    roleType === Const.CONSUMER_MANAGER || roleType === Const.CONSUMER_VIEWER;

  return { prefs, isProvider, isConsumer, handleChange };
};
