import React, { useState } from 'react';
import { Link as RouteLink } from 'react-router-dom';

import { styled, Avatar, MenuItem, Menu } from '@material-ui/core';
import { AccountCircle } from '@material-ui/icons';

import pkg from '../../../../../package.json';
import { useAuth0 } from '../../../auth/react-auth0-spa';
import { Const } from '../../../store/constants';
import { useUserCanViewByRoleType } from '../../../hooks/useUserCanViewByRoleType';

export const ProfileMenu = () => {
  const { logout, user } = useAuth0();
  const [isAdmin] = useUserCanViewByRoleType([Const.ADMIN_ADMIN]);

  const [isViewer] = useUserCanViewByRoleType([
    Const.CONSUMER_VIEWER,
    Const.PROVIDER_VIEWER,
    Const.OWNER_VIEWER,
  ]);

  const { version } = pkg;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => setAnchorEl(event.currentTarget);

  const handleClose = () => setAnchorEl(null);

  const handleLogout = () => {
    handleClose();
    return logout({ returnTo: window.location.origin });
  };

  return (
    <UserArea>
      <User onClick={handleMenu}>
        <UserEmail>{user?.email}</UserEmail>
        <UserAvatar>
          {user?.picture && user?.name ? (
            <Avatar alt={user.name} src={user.picture} />
          ) : (
            <AccountCircleStyled />
          )}
        </UserAvatar>
      </User>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        elevation={3}
        getContentAnchorEl={null}
        keepMounted
        onClick={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <MenuItem onClick={handleClose} component={RouteLink} to="/profile">
          Profile
        </MenuItem>

        {!isAdmin && !isViewer && (
          <MenuItem
            onClick={handleClose}
            component={RouteLink}
            to="/email_preferences"
          >
            Email Preferences
          </MenuItem>
        )}

        {isAdmin && (
          <MenuItem onClick={handleClose} component={RouteLink} to="/about">
            About
          </MenuItem>
        )}

        <MenuItem onClick={handleLogout}>
          <div>Log out</div>
        </MenuItem>

        <MenuItem
          dense
          style={{ justifyContent: 'flex-end', minWidth: '160px' }}
        >
          <span style={{ fontSize: '11px' }}>v{version}</span>
        </MenuItem>
      </Menu>
    </UserArea>
  );
};

const UserArea = styled('div')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '0.875rem',
  color: '#111216',
}));

const User = styled('div')(() => ({
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    cursor: 'pointer',
  },
}));

const UserEmail = styled('div')(() => ({
  marginRight: 15,
  minWidth: 60,
}));

const UserAvatar = styled('div')(() => ({
  alignItems: 'center',
  display: 'flex',
}));

const AccountCircleStyled = styled(AccountCircle)(() => ({
  fill: '#666',
  '&&': {
    width: '38px',
    height: '38px',
  },
}));
