import { createSlice, createSelector } from '@reduxjs/toolkit';

import { baseApiWithToken, handleError } from '../../../store/api';

export const initialState = {
  loading: false,
  error: false,
  errorData: {
    status: '',
    message: '',
    errors: [],
  },
  users: [],
};

/** Slice */
export const {
  actions: { loading, loaded, remove, add, update, failure, clearError },
  reducer: users,
} = createSlice({
  name: 'users',
  initialState,
  reducers: {
    loading: (draft) => {
      draft.loading = true;
    },
    loaded: (draft, action) => {
      draft.loading = initialState.loading;
      const { agents } = action?.payload;
      draft.users = agents;
    },
    add: (draft, action) => {
      draft.users.push(action.payload);
    },
    update: (draft, action) => {
      const { agentId } = action.payload;
      const index = draft.users.findIndex((item) => item.agentId === agentId);
      if (index !== -1) {
        draft.users[index] = action.payload;
      }
    },
    remove: (draft, action) => {
      const { agentId } = action.payload;
      const remaining = draft.users.filter((item) => item.agentId !== agentId);
      draft.users = remaining;
    },
    failure: (draft, action) => {
      draft.loading = initialState.loading;
      draft.error = true;
      draft.errorData = action?.payload;
    },
    clearError: (draft) => {
      draft.loading = initialState.loading;
      draft.error = initialState.error;
      draft.errorData = initialState.errorData;
    },
  },
});

/** Selectors */
export const selectRootUsers = (state) => state;
const sliceSelector = (state) => state.users;

export const selectLoading = (state) => sliceSelector(state).loading;
export const selectError = (state) => sliceSelector(state).error;
export const selectErrorData = (state) => sliceSelector(state).errorData;
export const selectErrorResponse = createSelector(
  [selectError, selectErrorData],
  (error, data) => {
    return {
      hasError: error,
      hasErrorData: data,
    };
  },
);

export const selectUsers = (state) => sliceSelector(state).users;

export const selectUsersNoEditCurrent = createSelector(
  [selectUsers, selectRootUsers],
  (users, state) => {
    return users.map((user) => {
      const userNew = { ...user };
      if (user.agentId === state.me.me.agentId) {
        userNew.noEdit = true;
      } else {
        userNew.noEdit = false;
      }
      return userNew;
    });
  },
);

/** Side Effects */
export const createUser = (payload) => async (dispatch) => {
  return baseApiWithToken()
    .url('/agent')
    .post(payload)
    .json((json) => dispatch(add(json, payload)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const editUser = (payload) => async (dispatch) => {
  const { agentId } = payload;
  return baseApiWithToken()
    .url(`/agent/${agentId}`)
    .put(payload)
    .json((json) => dispatch(update(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const deleteUser = (payload) => async (dispatch) => {
  const { agentId } = payload;
  return baseApiWithToken()
    .url(`/agent/${agentId}`)
    .delete()
    .json((json) => dispatch(remove(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};

export const getUsers = () => async (dispatch) => {
  dispatch(loading());
  return baseApiWithToken()
    .url('/agent')
    .get()
    .json(async (json) => dispatch(loaded(json)))
    .catch((error) => dispatch(failure(handleError(error))));
};
