import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const ScrollToTop = ({ target }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    if (target) {
      target.current.scrollTo(0, 0);
    }
  }, [pathname, target]);

  return null;
};
